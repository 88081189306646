<template>

  <div v-if="data">

    <Scatter :chart-options="chartOptions" :chart-data="data"/>

  </div>
</template>
<script>
import {defineComponent} from 'vue'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import {Scatter} from 'vue-chartjs'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)
export default defineComponent({
  name: "ChartRangeBar",
  components: {
    Scatter
  },
  props: ['data'],
  methods: {
    ready() {
      this.loading = false
    },
    onBeforeMount() {
      this.loading = true
    }
  },
  data() {
    return {
      loading: false,
      chartOptions: {
        plugins: {
          legend: {
            display: false
          }
        },
        elements: {
          point: {
            radius: 10,
            hoverRadius: 12,
            backgroundColor: '#f88600'
          }
        },
        responsive: true,
        scales: {
          drug: {
            type: 'category',
            position: 'left',
          },
          day: {
            type: 'linear',
            position: 'bottom',
            offset: true,
            ticks: {
              stepSize: 1,
              callback: function(value) {
                return 'Tag ' + value;
              }
            }
          }
        }
      }
    }
  }
})
</script>
<style scoped>

</style>
