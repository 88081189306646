<template>
  <div>
    <el-row>
      <el-col :lg="{span:8, offset:8}" :md="{span:10, offset:7}" :sm="{span:12, offset:6}" :xs="{span:16, offset:4}">

        <div class="mt-5 text-right">
            <a class="text-black-50" href="/client-settings"><i class="el-icon-setting"></i></a>
        </div>

        <div class="text-sm-center mb-5">
          <el-image :src="require(`./assets/dacy.png`)" fit="contain"></el-image>
        </div>

        <el-form label-width="" :model="form">
          <el-form-item label-width="100px" label="Benutzername">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label-width="100px" label="Passwort">
            <el-input type="password" v-model="form.password" :minlength="8"></el-input>
          </el-form-item>
          <el-form-item class="text-sm-center">
            <el-button class="w-100" type="primary" @click="submitForm()">Anmelden</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <el-dialog
      v-model="showTermsAcceptanceDialog"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="75%">

      <el-row>
        <el-col :span="12" style="word-break: break-word;">
          <h3>Geschäftsbedingungen</h3>
          Die TZN Tumorzentrum Niederrhein GmbH überlässt den Onlinezugang zur Software Dacy für begrenzte Zeit. Die Weitergabe der Zugangsdaten ist verboten. Die Weitergabe jeglicher Informationen über die Software Dacy an Personen ausserhalb des berechtigten Personenkreises ist ohne ausdrückliche Erlaubnis der TZN Tumorzentrum Niederrhein GmbH verboten. Der Nutzer haftet für die Verletzung dieser Verschwiegenheitspflicht gegenüber der TZN Tumorzentrum Niederrhein GmbH.

          <p class="mt-5">
            <el-checkbox v-model="termsAccepted" label="Ich habe die Geschäftsbedingungen gelesen und akzeptiert." size="large" border  />
          </p>
        </el-col>
        <el-col :span="11" :offset="1" style="word-break: break-word;">
          <h3>Haftungsausschluss</h3>
          Die Software Dacy dient der Dokumentation von Tumorbehandlungen. Die in der Software angebotenen wissenschaftlichen, therapeutischen und pharmakologischen Informationen (Knowledebase) dienen der Vereinfachung der Dokumentation. Es kann keine Gewähr für die Vollständigkeit und Richtigkeit dieser Informationen gegeben werden. Die Benutzer der Software sind aufgefordert fehlende oder falsche Informationen der Redaktion über die Benachrichtigungsfunktion der Software mitzuteilen. Die behandelnden Ärzte sind verpflichtet die von ihnen durchführten Behandlungen entsprechend der Leitlinien der Fachgesellschaften, der Fachinformationen und auf Grundlage des aktuellen Standes der Wisssenschaft durchzuführen. Abweichende oder fehlende Informationen in der Software Dacy dürfen nicht als Grundlage für die Behandlung von Patienten herangezogen werden.
          <p class="mt-5">
            <el-checkbox v-model="disclaimerAccepted" label="Ich habe den Haftungsausschluss gelesen und akzeptiert." size="large" border  />
          </p>
        </el-col>
      </el-row>

      <div class="text-center">
        <hr/>
        <el-button :disabled="!disclaimerAccepted || !termsAccepted" type="warning" @click="onSaveAcceptance">Speichern und weiter zu DACY</el-button>
      </div>

    </el-dialog>

  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "Login",
  props: [],
  components: {
  },
  data () {
    return {
      form: {
        username: '',
        password: ''
      },
      termsAccepted: false,
      disclaimerAccepted: false,
      loginResult: null,
      showTermsAcceptanceDialog: false,
    }
  },
  mounted: function () {
  },
  computed: {
      ...mapGetters('config', ['route_name_after_login']),
  },
  /*
  watch: {
    var: function (val) {
    }
  },
  */

  methods: {
    submitForm() {
      this.$auth.login({
        data: this.form
      }).then((result) => {

        this.loginResult = result.data
        this.termsAccepted = false
        this.disclaimerAccepted = false

        if(result.data.user.terms_accepted_at === null || result.data.user.disclaimer_accepted_at === null) {
          this.showTermsAcceptanceDialog = true
        }
        else {
          this.processLogin()
        }

      }).catch(() => {
        this.$notify.error({
          message: "Ihre eingegebenen Zugangsdaten waren nicht korrekt",
          position: 'bottom-left'
        });
      });
    },
    processLogin() {

      this.$auth.token('dacy-token', this.loginResult.token.token)
      this.$auth.user(this.loginResult.user)

      this.$router.push({
        name: this.route_name_after_login
      });

    },
    async onSaveAcceptance() {

      try {
        // don't set auth user for app, just send token for authorization, login will redo after that and will complete the login and the auth user. ensures that the login is only happening if terms are accepted
        await axios.post('/auth/acceptance', null, { headers: {"Authorization" : `Bearer ${this.loginResult.token.token}`} })
        this.submitForm()
      }
      catch (e) {
        console.log(e)
      }

    }

}

}
</script>
<style>
body {
  background-color:#FFF;
}
</style>
